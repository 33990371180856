<template>
  <span>
    <v-tooltip top v-if="blogAdmin">
      <template v-slot:activator="{ on, attrs }">
        <v-icon class="ml-1" v-bind="attrs" v-on="on" small>$vuetify.icons.shieldAccount</v-icon>
      </template>
      <span>{{ $t("Administrator") }}</span>
    </v-tooltip>

    <v-tooltip top v-if="isBlocked">
      <template v-slot:activator="{ on, attrs }">
        <v-icon class="ml-1" color="red" v-bind="attrs" v-on="on" small>$vuetify.icons.accountCancel</v-icon>
      </template>
      <span>{{ $t("Blocked") }}</span>
    </v-tooltip>
  </span>
</template>

<i18n>
    {
    "en": {
    "Administrator": "Administrator",
    "Blocked": "Member is blocked from diary"
    },
    "sv": {
    "Administrator": "Administratör",
    "Blocked": "Användaren är avstängd från dagboken"
    }
    }
</i18n>


<script>
export default {
  props: {
    blogAdmin: {
      type: Boolean,
      default: false,
    },
    isBlocked: {
      type: Boolean,
      default: false,
    },
  },
};
</script>