<template>
  <form-dialog
    v-if="!is_admin"
    v-model="abuse_dialog"
    :is-saving="is_abuse_sending"
    :title="$t('Abuse')"
    v-on:submit="sendAbuse"
    :submit-text="$t('Button.Send')"
  >
    <template v-slot:content>
      <v-form ref="abuse_form" v-model="abuse_form">
        <v-textarea
          v-model="abuse.text"
          :label="$t('WhyAbuse')"
          flat
          no-resize
          :rules="[(v) => !!v || '']"
          required
          :disabled="is_abuse_sending"
          :loading="is_abuse_sending"
          outlined
          solo
        ></v-textarea>
      </v-form>
      <error-box :error="abuse_error"></error-box>
    </template>
  </form-dialog>
</template>

<i18n>
    {
    "en": {
      "Abuse": "Abuse",
      "WhyAbuse": "Why do you want to report this post?",
      "AbuseSent": "Abuse sent!"
    },
    "sv": {
      "Abuse": "Anmäl",
      "WhyAbuse": "Varför vill du anmäla detta inlägg?",
      "AbuseSent": "Anmälan skickades!"
    }
    }
</i18n>

<script>
import { mapActions, mapState } from "vuex";
import { blogPostService } from "../../../_services";

export default {
  name: "abuse_dialog",
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
    }),
  },
  data: () => ({
    abuse_dialog: false,
    abuse_form: false,
    abuse: {
      text: "",
    },
    is_abuse_sending: false,
    abuse_error: null,

    blog_post: null,

    comment: null,
    comment_dataSource: null,
  }),
  created: function () {},
  methods: {
    ...mapActions({}),

    open() {
      this.abuse_error = null;
      this.is_abuse_sending = false;
      this.abuse_dialog = true;
      this.abuse.text = "";
    },

    abuseBlogPost(post) {
      this.open();
      this.blog_post = { ...post };
    },
    abuseComment(comment, dataSource) {
      this.open();
      this.comment = { ...comment };
      this.comment_dataSource = dataSource;
    },

    sendAbuse() {
      var self = this;
      self.abuse_error = null;
      self.is_abuse_sending = false;

      if (self.$refs.abuse_form.validate()) {
        self.is_abuse_sending = true;

        if (self.blog_post) {
          self.sendBlogPostAbuse();
        } else if (self.comment) {
          self.sendCommentAbuse();
        }
      }
    },
    sendBlogPostAbuse() {
      var self = this;

      blogPostService
        .abuse(self.blog_post.id, self.abuse.text)
        .then(function () {
          self.abuse_dialog = false;
          self.is_abuse_sending = false;
          self.$successNoty(self.$t("AbuseSent"));
        })
        .catch(function (error) {
          self.abuse_error = error;
          self.is_abuse_sending = false;
        });
    },
    sendCommentAbuse() {
      self.comment_dataSource
        .abuse(self.comment.id, self.abuse.text)
        .then(function () {
          self.abuse_dialog = false;
          self.is_abuse_sending = false;
          self.$successNoty(self.$t("AbuseSent"));
        })
        .catch(function (error) {
          self.abuse_error = error;
          self.is_abuse_sending = false;
        });
    },
  },
};
</script>