<template>
  <v-container class="album-media-page diary-page">
    <v-card min-height="300" class="pb-15" elevation="1">
      <v-skeleton-loader :loading="is_loading" type="article">
        <div>
          <template v-if="!error">
            <v-card-title>
              <h2 class="text-h6" style="margin-right: 70px">
                {{ media.title | capitalize }}
              </h2>

              <v-spacer></v-spacer>
              <template v-if="is_admin">
                <media-menu
                  v-on:updated="onUpdated"
                  :media="media"
                  v-on:modified="onModified"
                  v-on:deleted="onDeleted"
                ></media-menu>
              </template>
            </v-card-title>

            <v-card-text>
              <v-row class="pr-4 mb-0">
                <v-breadcrumbs
                  :items="breadcrumbs"
                  divider="/"
                  class="breadcrumbs mt-0 pb-1 pt-0"
                ></v-breadcrumbs>
              </v-row>

              <div class="text-center grey lighten-5">
                <template v-if="!image_error">
                  <v-img
                    :src="$imagehelpers.mediaWidth(media.image, 800)"
                    max-height="700"
                    min-height="100"
                    contain
                    @click="current_image = 0"
                    v-on:error="image_error = true"
                    class="pointer"
                    mx-auto
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                    <v-overlay absolute :value="is_uploading">
                      <v-progress-circular
                        indeterminate
                        size="50"
                      ></v-progress-circular>
                    </v-overlay>
                  </v-img>
                  <Tinybox
                    v-if="media.image"
                    no-thumbs
                    :images="getImages(media.image)"
                    v-model="current_image"
                  />
                </template>
                <div
                  v-if="image_error"
                  style="height: 250px"
                  class="text-center"
                >
                  <v-icon style="font-size: 100px" class="mt-12 mb-5"
                    >$vuetify.icons.fasImage</v-icon
                  >
                  <h3>{{ $t("ImageMissing") }}</h3>
                </div>
              </div>
              <div
                v-if="media.children && media.children.length > 0"
                class="text-center children-photo pt-1 pb-3"
              >
                <span v-if="media.children.length == 1">{{
                  $t("ChildInPhoto")
                }}</span>
                <span v-else>{{ $t("ChildrenInPhoto") }}</span>
                <template v-for="(child, i) in media.children">
                  <router-link :key="child.id" :to="getChildUrl(child)">{{
                    child.name | capitalize
                  }}</router-link>
                  <template v-if="i < media.children.length - 1">, </template>
                </template>
              </div>

              <div class="mt-3 pb-10 text-fix">{{ media.description | capitalize }}</div>

              <v-divider></v-divider>
              <template v-if="media.allow_comments">
                <h2 class="mt-2">{{ $t("Comments") }}</h2>
                <comments v-if="!comment_id" :media="media"></comments>
                <post-comment
                  v-if="comment_id"
                  :media="media"
                  :id="comment_id"
                ></post-comment>
              </template>
              <div v-if="!media.allow_comments" class="text-center pb-10 pt-10">
                {{ $t("NoCommentsAllowed") }}
              </div>
            </v-card-text>
          </template>
          <error-page
            :error="error"
            v-if="error"
            :home="'/' + diary.name"
          ></error-page>
        </div>
      </v-skeleton-loader>
    </v-card>
  </v-container>
</template>

<i18n>
    {
    "en": {
      "NoMedia": "There is no media to show!",
      "AddMedia": "Add photo",
      "Album": "Album",
      "Comments": "Comments",
      "Photo": "Photo",
      "ImageMissing": "Image missing",
      "NoCommentsAllowed": "Comments have been disabled.",
      "SuccessChangeCover": "Cover photo has been updated!",
      "Untitled": "Untitled",
      "ChildInPhoto": "Child in photo: ",
      "ChildrenInPhoto": "Children in photo: "
    },
    "sv": {
      "NoMedia": "Det finns inget uppladdat media att visa.",
      "AddMedia": "Lägg till foton",
      "Album": "Album",
      "Comments": "Kommentarer",
      "Photo": "Bild",
      "ImageMissing": "Bild saknas",
      "NoCommentsAllowed": "Kommentarer har inaktiverats.",
      "SuccessChangeCover": "Omslagsbilden har uppdaterades!",
      "Untitled": "Titel saknas",
      "ChildInPhoto": "Barn i bilden: ",
      "ChildrenInPhoto": "Barn i bilden: "
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
import { albumsService } from "@/_services";
import MediaComments from "@/components/diary/comments/MediaComments.vue";
import CommentItem from "@/components/diary/comments/MediaCommentItem.vue";
import Menu from "@/components/diary/helpers/MediaItemMenu.vue";
import Tinybox from "vue-tinybox";

export default {
  name: "diary_album_profile",
  metaInfo() {
    return {
      titleTemplate:
        this.media.title +
        " - " +
        this.diary.title +
        " | " +
        process.env.VUE_APP_NAME,
      meta: [
        {
          property: "og:title",
          content: this.media.title + " - " + this.diary.title,
          vmid: "og:title",
        },
        {
          name: "description",
          content: this.media.description,
          vmid: "description",
        },
        {
          property: "og:description",
          content: this.media.description,
          vmid: "og:description",
        },

        {
          property: "og:image",
          content: this.$imagehelpers.mediaWidth(this.media.image, 800),
          vmid: "og:image",
        },
        { property: "og:image:width", content: "800", vmid: "og:image:width" },
        {
          property: "og:image:height",
          content: "300",
          vmid: "og:image:height",
        },
        {
          property: "twitter:image:src",
          content: this.$imagehelpers.mediaWidth(this.media.image, 800),
          vmid: "twitter:image:src",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
    }),
  },
  components: {
    comments: MediaComments,
    "post-comment": CommentItem,
    "media-menu": Menu,
    Tinybox
  },
  data: () => ({
    media: {},
    is_loading: false,
    is_uploading: false,
    error: null,
    breadcrumbs: [],
    meta: {},
    current_image: null,
    image_error: false,

    comment_id: null,
  }),
  created: function () {
    this.loadMedia();
  },
  methods: {
    ...mapActions({
      deletePhoto: "diary/albums/deleteMedia",
      changeAlbumCover: "diary/albums/changeAlbumCover",
    }),
    createBreadcrumbs() {
      var self = this;
      if (self.media.album) {
        self.breadcrumbs.push({
          text: self.$t("Album"),
          disabled: false,
          exact: true,
          to: "/" + self.diary.name + "/album",
        });
        self.breadcrumbs.push({
          text: self.$options.filters.capitalize(self.media.album.title),
          disabled: false,
          to: "/" + self.diary.name + "/album/" + self.media.album.url_name,
        });
      }
    },
    loadMedia() {
      var self = this;
      self.error = null;
      self.is_loading = true;
      var urlName = self.$route.params.id;
      this.comment_id = self.$route.params.commentid;

      albumsService
        .getMedia(urlName)
        .then(function (response) {
          self.media = response.data;

          if (self.media && !self.media.title)
            self.media.title = self.$t("Untitled");

          self.createBreadcrumbs();
          self.is_loading = false;
        })
        .catch(function (error) {
          self.error = error;
          self.is_loading = false;
        });
    },
    onDeleted() {
      this.$router.push(
        "/" + this.diary.name + "/album/" + this.media.album.url_name
      );
    },

    getChildUrl(child) {
      return "/" + this.diary.name + "/child/" + child.url_name;
    },

    getImages(image) {
      return [image.original];
    },
    onUpdated(media) {
      if (media.title) this.media.title = media.title;

      if (media.description) this.media.description = media.description;

      if (media.backdated_time)
        this.media.backdated_time = media.backdated_time;

      if (media.children) this.media.children = media.children;
    },
    onModified(image) {
      this.media.image = image;
    },
  },
  watch: {
    $route() {
      this.comment_id = this.$route.params.id;
    },
  },
};
</script>

<style>
.media-header {
  word-break: normal !important;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.breadcrumbs a {
  font-size: 12px !important;
}

.breadcrumbs a:hover {
  text-decoration: underline;
}

.children-photo {
  color: gray;
  font-size: 12px;
}
.children-photo a {
  text-decoration: none;
}
.children-photo a:hover {
  text-decoration: underline;
}
</style>