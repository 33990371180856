<template>
    <v-skeleton-loader :loading="is_loading" transition="scale-transition" type="list-item-avatar-three-line">
        <v-col cols="12" class="pa-0">
            <template v-if="!error">
                <v-col cols="12" v-if="!comment" class="text-center">
                    {{ $t("NoComments") }}
                </v-col>
                <v-timeline align-top dense v-if="!error && comment">
                    <comment-item :data-service="dataService" v-on:deleted="onDeleted" :comment="comment" :key="comment.id"></comment-item>
                </v-timeline>
            </template>
            <error-box :error="error" class="mt-4"></error-box>
        </v-col>
    </v-skeleton-loader>
</template>

<i18n>
    {
    "en": {
    "NoComments": "Comment not found!"
    },
    "sv": {
    "NoComments": "Kommentaren kunde inte hittas!"
    }
    }
</i18n>

<script>
    import { mapState } from 'vuex'
    import { mediacommentsService } from '../../../_services';
    import CommentItem from './CommentItem.vue'

    export default {
        name: 'diary_media_comment_item',
        props: ["id", "media"],
        computed: {
            ...mapState({
                user: state => state.account.user,
                diary: state => state.diary.profile,
                is_admin: state => state.diary.is_admin
            })
        },
        components: {
            'comment-item': CommentItem
        },
        data: () => ({
            dataService: mediacommentsService,

            comment: null,
            error: null,
            is_loading: false
        }),
        created: function () {
            this.loadComment(this.id);
        },
        methods: {
            loadComment(id) {
                var self = this;
                self.error = null;
                self.is_loading = true;

                mediacommentsService.getPost(id)
                    .then(function (response) {
                        self.comment = self.generateComment(response.data);
                        self.is_loading = false;
                    })
                    .catch(function (error) {
                        self.error = error;
                        self.is_loading = false;
                    });
            },
            generateComment(comment) {
                if (comment.parent && comment.parent.id) {
                    var parent = { ...comment.parent };
                    var child = { ...comment };

                    delete child.answers;
                    delete child.parent;
                    delete parent.answers;
                    delete parent.parent;

                    parent.answers = [];
                    parent.answers.push(child);

                    return parent;
                }

                return comment;
            },
            onDeleted() {
                this.$router.push('/' + this.diary.name + '/album/media/' + this.media.id);
            },
        }
    }
</script>

<style>
</style>