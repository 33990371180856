<template>
  <v-container class="albums-page diary-page">
    <v-card min-height="300" elevation="1">
      <v-card-title>
        <h2 class="text-h6">{{ $t("Album") }}</h2>

        <v-spacer></v-spacer>
        <v-btn
          v-if="is_admin"
          text
          color="primary"
          @click="newAlbum"
          class="text-none text-subtitle-1"
        >
          {{ $t("CreateAlbum") }}
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-skeleton-loader :loading="is_loading" max-width="300" type="card">
          <div>
            <template v-if="!error">
              <div v-if="!albums || albums.length < 1" class="text-center">
                {{ $t("NoAlbums") }}
              </div>
              <v-row v-if="albums && albums.length > 0">
                <v-col
                  xs="6"
                  sm="4"
                  md="3"
                  cols="6"
                  v-for="item in albums"
                  :key="item.id"
                  class="album-column"
                >
                  <album-item :album="item"></album-item>
                </v-col>

                <v-col
                  xs="12"
                  cols="12"
                  v-if="unit_pages > 1"
                  class="text-center"
                >
                  <v-pagination
                    v-model="page"
                    :length="unit_pages"
                    :total-visible="7"
                  ></v-pagination>
                </v-col>
              </v-row>
            </template>
            <error-page :error="error" v-if="error"></error-page>
          </div>
        </v-skeleton-loader>
      </v-card-text>
    </v-card>
    <form-dialog ref="albumDialog" v-if="is_admin"></form-dialog>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "Album": "Albums",
    "NoAlbums": "There is no albums to show!",
    "CreateAlbum": "Create album",
    "PageDescription": "Visit my photoalbums in my diary."
    },
    "sv": {
    "Album": "Album",
    "NoAlbums": "Det finns inga album att visa.",
    "CreateAlbum": "Skapa album",
    "PageDescription": "Besök mina fotoalbum som jag har i min dagbok."
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
import AlbumItem from "@/components/diary/helpers/AlbumItem.vue";
const NewAlbumDialog = () =>
  import("@/components/diary/dialogs/AdminAlbumDialog.vue");

export default {
  name: "diary_album",
  metaInfo() {
    return {
      titleTemplate:
        this.$t("Album") +
        " - " +
        this.diary.title +
        " | " +
        process.env.VUE_APP_NAME,
      meta: [
        {
          property: "og:title",
          content: this.$t("Album") + " | " + this.diary.title,
          vmid: "og:title",
        },
        {
          property: "og:description",
          content: this.$t("PageDescription"),
          vmid: "og:description",
        },
        {
          name: "description",
          content: this.$t("PageDescription"),
          vmid: "description",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,

      unit_pages: (state) => state.diary.albums.unit_pages,
      loaded_page: (state) => state.diary.albums.current_page,
      albums: (state) => state.diary.albums.list,
    }),
  },

  components: {
    "album-item": AlbumItem,
    "form-dialog": NewAlbumDialog,
  },
  data: () => ({
    is_loading: false,
    error: null,

    page: 1,
  }),
  created: function () {
    this.setFromUrl();
    this.loadAlbums();
  },
  methods: {
    ...mapActions({
      load: "diary/albums/load",
    }),

    loadAlbums() {
      var self = this;

      if (self.page == self.loaded_page) return;

      self.is_loading = true;
      self.error = null;

      this.load(self.page)
        .then(function () {
          self.is_loading = false;
        })
        .catch(function (error) {
          self.error = error;
          self.is_loading = false;
        });
    },
    setFromUrl() {
      this.page = 1;

      if (this.$route.query.page) this.page = parseInt(this.$route.query.page);
    },

    getTitle() {
      return this.$t("Album");
    },
    getAlbumUrl(urlName) {
      return "/" + this.diary.name + "/album/" + urlName;
    },
    newAlbum() {
      this.$refs.albumDialog.open(this.diary.name);
    },
  },
  watch: {
    $route() {
      this.setFromUrl();
      this.loadAlbums();
    },
    page() {
      var self = this;

      if (parseInt(self.$route.query.page) == self.page) return;
      self.$router.push({ query: { page: self.page } });
    },
    loaded_page() {
      if (!this.loaded_page) {
        this.setFromUrl();
        this.loadAlbums();
      }
    },
  },
};
</script>

<style>
.album-column {
  flex: none !important;
}

.albums-header {
  word-break: normal !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.albums-description {
  word-break: normal !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>