<template>
  <form-dialog
    v-if="is_admin"
    v-model="dialog"
    :is-saving="is_sending"
    :title="$t('BlockUser')"
    v-on:submit="send"
    :submit-text="$t('Block')"
    show-Cancel
  >
    <template v-slot:content>
      <p v-if="diary.privacy != $enums.DIARY_PRIVACY.OpenForAll">
        <b>{{ username | capitalize }}</b> {{ $t("Desc1") }}
      </p>
      <p v-if="diary.privacy == $enums.DIARY_PRIVACY.OpenForAll">
        <b>{{ username | capitalize }}</b> <span v-html="$t('Desc2')"></span>
      </p>
      <error-box :error="error"></error-box>
    </template>
  </form-dialog>
</template>

<i18n>
    {
    "en": {
      "Block": "Block",
      "BlockUser": "Block user",
      "Desc1": "won't be able to read or write in your diary.",
      "Desc2": "won't be able to write in your diary.<br />If you want to block the user from read your diary, change the Privacy from, 'Open for all' to 'Only open for members'.",
      "UserBlocked": "Member blocked!"
    },
    "sv": {
      "Block": "Blockera",
      "BlockUser": "Blockera användare",
      "Desc1": "kommer inte att kunna läsa eller skriva i din dagbok.",
      "Desc2": "kommer inte att kunna skriva i din dagbok. <br /> Om du vill blockera användaren från att läsa din dagbok ändrar du sekretessen från 'Öppna för alla' till 'Endast öppet för medlemmar'.",
      "UserBlocked": "Användare blockerad!"
    }
    }
</i18n>

<script>
import { mapActions, mapState } from "vuex";
import { blogBlockService } from "../../../_services";

export default {
  name: "block_user_dialog",
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
    }),
  },
  data: () => ({
    dialog: false,
    is_sending: false,
    error: null,
    username: "",
  }),
  created: function () {},
  methods: {
    ...mapActions({}),

    open() {
      this.error = null;
      this.is_sending = false;
      this.dialog = true;
    },

    blockUser(username) {
      this.open();
      this.username = username;
    },

    send() {
      var self = this;
      self.error = null;

      self.is_sending = true;

      blogBlockService
        .add(self.diary.name, self.username)
        .then(function () {
          self.dialog = false;
          self.is_sending = false;
          self.$successNoty(self.$t("UserBlocked"));
          self.$emit("blocked", self.username);
        })
        .catch(function (error) {
          self.error = error;
          self.is_sending = false;
        });
    },
  },
};
</script>