<template>
  <v-container class="album-profile-page diary-page">
    <v-card min-height="300" elevation="1">
      <v-skeleton-loader :loading="is_loading" type="article">
        <div>
          <template v-if="!error">
            <v-card-title>
              <h2 class="text-h6" style="margin-right: 70px">
                {{ album.title | capitalize }}
              </h2>

              <v-spacer></v-spacer>
              <template v-if="is_admin">
                <button-menu
                  depressed
                  :items="admin_menu"
                  btn-icon="$vuetify.icons.dotsHorizontal"
                ></button-menu>

                <edit-dialog
                  ref="editDialog"
                  v-on:updated="onUpdated"
                ></edit-dialog>
                <privacy-dialog ref="privacyDialog"></privacy-dialog>
              </template>
            </v-card-title>
            <v-card-text class="text--primary">
              <my-clamp :rows="1" :text="album.description"></my-clamp>
            </v-card-text>

            <v-card-text>
              <v-skeleton-loader
                :loading="medias.is_loading"
                transition="scale-transition"
                max-width="300"
                type="card"
              >
                <v-row>
                  <template v-if="!error">
                    <template v-if="is_admin || medias.items.length > 0">
                      <v-col
                        xs="6"
                        md="3"
                        sm="4"
                        cols="6"
                        v-if="album.can_upload"
                      >
                        <v-card
                          height="180"
                          class="text-center grey--text text--darken-1"
                          :to="{ name: 'diary_album_upload' }"
                          outlined
                        >
                          <v-icon size="80" class="mt-7"
                            >$vuetify.icons.plus</v-icon
                          >
                          <div
                            class="
                              subtitle-1
                              text-center
                              pt-1
                              font-weight-medium
                            "
                          >
                            {{ $t("AddMedia") }}
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        xs="6"
                        md="3"
                        cols="6"
                        sm="4"
                        v-for="item in medias.items"
                        :key="item.id"
                      >
                        <image-item
                          :image="item"
                          v-on:updated="onMediaUpdated"
                          v-on:deleted="onDeleted"
                        ></image-item>
                      </v-col>

                      <v-col
                        xs="12"
                        cols="12"
                        v-if="medias.unit_pages > 1"
                        class="text-center"
                      >
                        <v-pagination
                          v-model="medias.page"
                          :length="medias.unit_pages"
                          :total-visible="7"
                        ></v-pagination>
                      </v-col>
                    </template>
                    <v-col
                      xs="12"
                      cols="12"
                      v-if="
                        (!medias.items || medias.items.length < 1) &&
                        (!is_admin || !album.can_upload)
                      "
                      class="text-center"
                      >{{ $t("NoMedia") }}</v-col
                    >
                  </template>
                  <error-box :error="medias.error" class="mt-4"></error-box>
                </v-row>
              </v-skeleton-loader>
            </v-card-text>
          </template>
          <error-page
            :error="error"
            v-if="error"
            :home="'/' + diary.name"
          ></error-page>
        </div>
      </v-skeleton-loader>
    </v-card>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "NoMedia": "There is no media to show!",
    "AddMedia": "Add photo",
    "Delete": "Delete album",
    "EditInformation": "Edit album",
    "UploadImages": "Upload photos",
    "Manage": "Manage",
    "ConfirmDeleteAlbum": "Are you sure you want to delete this album?<br />All photos will be deleted.",
    "AlbumDeleted": "Album deleted!",
    "ManageImages": "Manage photos",
    "EditPrivacy": "Edit privacy",
    "Album": "Album"
    },
    "sv": {
    "NoMedia": "Det finns inget uppladdat media att visa.",
    "AddMedia": "Lägg till foton",
    "Delete": "Ta bort album",
    "EditInformation": "Redigera album",
    "UploadImages": "Ladda upp bilder",
    "Manage": "Administrera",
    "ConfirmDeleteAlbum": "Är du säker på att du vill ta bort albumet?<br />Alla bilder som finns i albumet kommer att tas bort.",
    "AlbumDeleted": "Albumet togs bort!",
    "ManageImages": "Hantera bilder",
    "EditPrivacy": "Redigera sekretess",
    "Album": "Album"
    }
    }
</i18n>

<script>
import { mapState } from "vuex";
import { albumsService } from "@/_services";
import ImageItem from "@/components/diary/helpers/AlbumImageItem.vue";
import MyClamp from "@/components/global/MyClamp.vue";

export default {
  name: "diary_album_profile",
  metaInfo() {
    return {
      titleTemplate:
        this.album.title +
        " - " +
        this.diary.title +
        " | " +
        process.env.VUE_APP_NAME,
      meta: [
        {
          property: "og:title",
          content: this.album.title + " - " + this.diary.title,
          vmid: "og:title",
        },
        {
          name: "description",
          content: this.album.description,
          vmid: "description",
        },
        {
          property: "og:description",
          content: this.album.description,
          vmid: "og:description",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
    }),
    admin_menu: function () {
      var data = [];

      if (this.album.can_edit) {
        data.push({
          name: this.$t("EditInformation"),
          icon: "$vuetify.icons.fileDocumentEditOutline",
          click: this.editAlbum,
        });

        data.push({
          name: this.$t("EditPrivacy"),
          icon: "$vuetify.icons.earth",
          click: this.openPrivacyDialog,
        });
      }
      if (this.album.can_upload) {
        data.push({
          name: this.$t("UploadImages"),
          icon: "$vuetify.icons.cloudUploadOutline",
          to: { name: "diary_album_upload" },
        });
      }

      data.push({
        name: this.$t("ManageImages"),
        icon: "$vuetify.icons.imageOutline",
        to: { name: "diary_album_manage" },
      });

      if (this.album.can_delete)
        data.push({
          name: this.$t("Delete"),
          icon: "$vuetify.icons.trashCanOutline",
          click: this.deleteAlbum,
        });

      return data;
    },
  },
  components: {
    "edit-dialog": () =>
      import("@/components/diary/dialogs/AdminAlbumDialog.vue"),
    "image-item": ImageItem,
    "privacy-dialog": () =>
      import("@/components/diary/dialogs/PrivacyDialog.vue"),
    "my-clamp": MyClamp,
  },
  data: () => ({
    album: {},
    is_loading: false,
    error: null,

    medias: {
      page: 1,
      pagesize: 30,
      items: [],

      is_loading: false,
      error: null,
      unit_pages: 1,
    },
  }),
  created: function () {
    if (this.is_admin) {
      this.medias.pagesize = 31;
    } else {
      this.medias.pagesize = 32;
    }

    this.setFromUrl();
    this.loadAlbum();
  },
  methods: {
    loadAlbum() {
      var self = this;
      self.error = null;
      self.is_loading = true;

      var urlName = self.$route.params.name;
      var temp = self.$store.getters["diary/albums/getAlbumByUrlName"](urlName);

      if (temp) {
        self.album = temp;
        self.is_loading = false;
        self.loadMedias();
      } else {
        albumsService
          .loadAlbum(
            self.diary.name,
            urlName,
            self.medias.page,
            self.medias.pagesize
          )
          .then(function (response) {
            var data = response.data;

            self.album = data.album;
            self.is_loading = false;

            self.medias.items = data.medias.data;
            self.medias.unit_pages = data.medias.unit_pages;
            self.medias.page = data.medias.current_page;
            self.medias.is_loading = false;
          })
          .catch(function (error) {
            self.error = error;
            self.is_loading = false;
          });
      }
    },
    loadMedias() {
      var self = this;
      self.medias.error = null;
      self.medias.is_loading = true;

      albumsService
        .getAlbumMedia(self.album.id, self.medias.page)
        .then(function (response) {
          self.medias.items = response.data.data;
          self.medias.unit_pages = response.data.unit_pages;
          self.medias.page = response.data.current_page;
          self.medias.is_loading = false;
        })
        .catch(function (error) {
          self.medias.error = error;
          self.medias.is_loading = false;
        });
    },
    setFromUrl() {
      this.medias.page = 1;

      if (this.$route.query.page)
        this.medias.page = parseInt(this.$route.query.page);
    },
    editAlbum() {
      this.$refs.editDialog.editAlbum(this.album);
    },
    onUpdated(album) {
      this.album.title = album.title;
      this.album.description = album.description;
    },
    async deleteAlbum() {
      var self = this;

      const res = await self.$confirm(self.$t("ConfirmDeleteAlbum"));

      if (res) {
        self.is_loading = true;

        albumsService
          .delete(self.album.id)
          .then(function () {
            self.$successNoty(self.$t("AlbumDeleted"));
            self.$router.push("/" + self.diary.name + "/album");
            return;
          })
          .catch(function (error) {
            self.$ajaxErrorNoty(error);
            self.is_loading = false;
          });
      }
    },
    onMediaUpdated(media) {
      for (var i = 0; i < this.medias.items.length; i++) {
        var obj = this.medias.items[i];

        if (obj.id == media.id) {
          obj.description = media.description;
          obj.title = media.title;
          obj.backdated_time = media.backdated_time;
          return;
        }
      }
    },
    onDeleted(id) {
      for (var i = 0; i < this.medias.items.length; i++) {
        var obj = this.medias.items[i];

        if (obj.id == id) {
          this.medias.items.splice(i, 1);
          return;
        }
      }
    },
    openPrivacyDialog() {
      this.$refs.privacyDialog.openAlbum(this.album);
    },
  },
  watch: {
    $route() {
      this.setFromUrl();
      this.loadMedias();
    },
    "medias.page"() {
      var self = this;

      if (parseInt(self.$route.query.page) == self.medias.page) return;
      self.$router.push({ query: { page: self.medias.page } });
    },
  },
};
</script>

<style>
.media-header {
  word-break: normal !important;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>