import { render, staticRenderFns } from "./AlbumItem.vue?vue&type=template&id=6c0868a3&scoped=true&"
import script from "./AlbumItem.vue?vue&type=script&lang=js&"
export * from "./AlbumItem.vue?vue&type=script&lang=js&"
import style0 from "./AlbumItem.vue?vue&type=style&index=0&id=6c0868a3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c0868a3",
  null
  
)

/* custom blocks */
import block0 from "./AlbumItem.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardSubtitle,VCardTitle,VHover,VImg,VOverlay,VProgressCircular,VRow})
