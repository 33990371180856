<template>
  <v-timeline-item right class="comment-item">
    <template v-slot:icon>
      <router-link :to="'/user/' + comment.user.username">
        <user-avatar :user="comment.user" :size="50"></user-avatar>
      </router-link>
    </template>
    <v-card outlined>
      <v-card-title class="subtitle-2 pt-2 pb-2 grey lighten-2">
        <router-link :to="'/user/' + comment.user.username">{{
          comment.user.username | capitalize
        }}</router-link>

        <icons-helper
          :blog-admin="isBlogAdmin(comment.user.username)"
          :is-blocked="comment.user.is_blocked && is_admin"
        ></icons-helper>

        <router-link
          v-if="comment.link"
          :to="comment.link"
          class="caption pl-2 grey--text text--darken-2"
          >{{ sinceTime(comment.create_date) }}</router-link
        >
        <span
          v-if="!comment.link"
          class="caption pl-2 grey--text text--darken-2"
          >{{ sinceTime(comment.create_date) }}</span
        >
        <v-spacer></v-spacer>

        <button-menu
          icon
          :items="comment_menu"
          :loading="is_deleting"
          btn-icon="$vuetify.icons.dotsVertical"
        ></button-menu>
      </v-card-title>
      <v-card-text class="pt-4">
        <div class="comment-text text--primary">
          <render-string :string="getText(comment.text)" />
        </div>
        <v-card
          flat
          v-if="
            show_answer_box && (!comment.answers || comment.answers.length == 0)
          "
          class="mt-4"
        >
          <v-divider></v-divider>
          <v-form ref="answer_form" v-model="answer_form" class="pt-4">
            <v-card-text class="pa-0">
              <v-textarea
                auto-grow
                flat
                outlined
                autofocus
                :rules="[(v) => !!v || '']"
                required
                counter="700"
                :disabled="is_answering || is_deleting"
                v-model="answer.text"
                :label="$t('AnswerComment')"
              ></v-textarea>
            </v-card-text>
            <v-card-actions>
              <error-inline :error="answer_error"></error-inline>
              <v-spacer></v-spacer>
              <v-btn
                @click="show_answer_box = false"
                text
                :disabled="is_answering || is_deleting"
                color="primary"
                class="text-none"
                >{{ $t("Button.Cancel") }}</v-btn
              >
              <v-btn
                @click="answerPublish"
                :loading="is_answering"
                :disabled="is_deleting"
                color="primary"
                class="text-none"
                >{{ $t("Button.Publish") }}</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card>

        <template v-if="comment.answers">
          <v-card
            v-for="answer in comment.answers"
            :key="answer.id"
            class="mt-4"
            flat
          >
            <v-divider class="mb-3"></v-divider>
            <div class="caption grey--text text--darken-2">
              {{ $t("Answer") }}:
            </div>
            <v-card-title class="pa-0">
              <router-link
                class="subtitle-2"
                :to="'/user/' + answer.user.username"
                >{{ answer.user.username | capitalize }}</router-link
              >
              <icons-helper
                :is-blocked="comment.user.is_blocked && is_admin"
                :blog-admin="isBlogAdmin(answer.user.username)"
              ></icons-helper>
              <span class="caption pl-2 grey--text text--darken-2">{{
                sinceTime(answer.create_date)
              }}</span>
              <v-spacer></v-spacer>

              <button-menu
                icon
                :items="getAnswerMenu(answer)"
                :disabled="is_deleting"
                :loading="answer.is_deleting"
                btn-icon="$vuetify.icons.dotsVertical"
                v-if="is_admin"
              ></button-menu>
            </v-card-title>
            <v-card-text class="pa-0 pt-1 comment-text text--primary">
              <render-string :string="getText(answer.text)" />
            </v-card-text>
          </v-card>
        </template>
      </v-card-text>
    </v-card>

    <abuse-dialog ref="abuseDialog" v-if="user && !is_admin"></abuse-dialog>
    <block-dialog
      v-on:blocked="onBlocked"
      ref="blockUserDialog"
      v-if="is_admin"
    ></block-dialog>
  </v-timeline-item>
</template>

<i18n>
    {
    "en": {
    "Abuse": "Abuse",
    "AnswerComment": "Answer comment",
    "Answer": "Answer",
    "ConfirmDeleteComment": "Are you sure you want to delete the comment?",
    "SuccessDelete": "Comment deleted!",
    "BlockMember": "Block member",
    "SuccessUnblock": "Member unblocked!",
    "UnblockMember": "Unblock member"
    },
    "sv": {
    "Abuse": "Anmäl",
    "AnswerComment": "Svara kommentar",
    "Answer": "Svar",
    "ConfirmDeleteComment": "Är du säker på att du vill ta bort kommentaren?",
    "SuccessDelete": "Kommentaren borttagen!",
    "BlockMember": "Blockera användare",
    "SuccessUnblock": "Användaren avblockerad!",
    "UnblockMember": "Avblockera användare"
    }
    }
</i18n>

<script>
import { mapState } from "vuex";
import AbuseDialog from "../dialogs/AbuseDialog.vue";
import BlockUserDialog from "../dialogs/BlockUserDialog.vue";
import IconsHelper from "../../global/IconsHelper.vue";
import { blogBlockService } from "../../../_services";
import UserAvatar from "@/components/avatars/UserAvatar.vue";
import { dateHelpers } from "@/_helpers";

export default {
  name: "diary_comment_item",
  props: ["comment", "dataService"],
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
    }),
    comment_menu: function () {
      var data = [];

      if (!this.is_admin)
        data.push({
          name: this.$t("Abuse"),
          icon: "$vuetify.icons.alertCircleOutline",
          click: this.openAbuseDialog,
        });

      if (this.is_admin) {
        if (!this.comment.answers || this.comment.answers.length == 0) {
          data.push({
            name: this.$t("AnswerComment"),
            icon: "$vuetify.icons.commentPlusOutline",
            click: this.answerAction,
          });
        }

        if (
          !this.isBlogAdmin(this.comment.user.username) &&
          !this.comment.user.is_blocked
        ) {
          data.push({
            name: this.$t("BlockMember"),
            icon: "$vuetify.icons.accountCancelOutline",
            click: function () {
              this.blockUser(this.comment.user.username);
            },
          });
        }

        if (
          !this.isBlogAdmin(this.comment.user.username) &&
          this.comment.user.is_blocked
        ) {
          data.push({
            name: this.$t("UnblockMember"),
            icon: "$vuetify.icons.accountCheckOutline",
            click: function () {
              this.unblockUser(this.comment.user.username);
            },
          });
        }

        data.push({
          name: this.$t("Button.Delete"),
          icon: "$vuetify.icons.trashCanOutline",
          click: this.deleteAction,
        });
      }

      return data;
    },
  },
  components: {
    "abuse-dialog": AbuseDialog,
    "block-dialog": BlockUserDialog,
    "icons-helper": IconsHelper,
    "user-avatar": UserAvatar,
  },
  data: () => ({
    is_deleting: false,

    show_answer_box: false,
    answer: {
      text: "",
    },
    is_answering: false,
    answer_error: null,
    answer_form: false,
  }),
  methods: {
    sinceTime: dateHelpers.timeSince,

    getAnswerMenu(answer) {
      var data = [];
      var self = this;

      data.push({
        name: this.$t("Button.Delete"),
        icon: "$vuetify.icons.trashCanOutline",
        click: function () {
          self.deleteAnswer(answer);
        },
      });

      return data;
    },

    getText(text) {
      return text
        .escapeHtml()
        .parseUsername()
        .replace("<a", "<router-link")
        .replace("href=", "to=")
        .replace("</a>", "</router-link>");
    },
    answerAction() {
      this.show_answer_box = true;
    },
    async deleteAction() {
      var self = this;

      const res = await self.$confirm(self.$t("ConfirmDeleteComment"));

      if (res) {
        self.is_deleting = true;

        self.dataService
          .delete(self.comment.id)
          .then(function () {
            self.is_deleting = false;
            self.$emit("deleted", self.comment.id);
            self.$successNoty(self.$t("SuccessDelete"));
          })
          .catch(function (error) {
            self.$ajaxErrorNoty(error);
            self.is_deleting = false;
          });
      }
    },
    answerPublish() {
      var self = this;
      self.answer_error = null;
      self.is_answering = false;

      if (self.$refs.answer_form.validate()) {
        self.is_answering = true;

        self.dataService
          .answer(self.comment.id, self.answer)
          .then(function (response) {
            self.show_answer_box = false;
            self.is_answering = false;
            self.answer.text = "";
            self.$emit("answer", {
              thread_id: self.comment.id,
              comment: response.data,
            });
            self.$refs.answer_form.resetValidation();
          })
          .catch(function (error) {
            self.answer_error = error;
            self.is_answering = false;
          });
      }
    },
    openAbuseDialog() {
      this.$refs.abuseDialog.abuseComment(this.comment, this.dataService);
    },
    async deleteAnswer(obj) {
      var self = this;

      const res = await self.$confirm(self.$t("ConfirmDeleteComment"));

      if (res) {
        self.$set(obj, "is_deleting", true);

        self.dataService
          .deleteAnswer(self.comment.id, obj.id)
          .then(function () {
            obj.is_deleting = false;
            self.$emit("answerDeleted", obj.id);
            self.$successNoty(self.$t("SuccessDelete"));
          })
          .catch(function (error) {
            self.$ajaxErrorNoty(error);
            obj.is_deleting = false;
          });
      }
    },
    blockUser(username) {
      this.$refs.blockUserDialog.blockUser(username);
    },
    unblockUser(username) {
      var self = this;
      blogBlockService
        .deleteUser(self.diary.name, username)
        .then(function () {
          self.$successNoty(self.$t("SuccessUnblock"));
          self.$emit("userUnblocked", username);
        })
        .catch(function (error) {
          self.$ajaxErrorNoty(error);
        });
    },
    isBlogAdmin(username) {
      if (this.diary.admins) {
        return this.diary.admins.find((p) => p.username == username) != null;
      }
      return false;
    },
    onBlocked(username) {
      this.$emit("userBlocked", username);
    },
  },
};
</script>

<style>
.comment-item a {
  text-decoration: none;
  color: black !important;
}

.comment-item a:hover {
  text-decoration: underline;
}

.comment-text {
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap; /* Opera <7 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* IE */
}

.comment-text a {
  font-weight: bold;
}

.comment-item .v-timeline-item__body > .v-card:not(.v-card--flat):after {
  border-right-color: #e0e0e0;
}
</style>