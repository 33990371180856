<template>
  <v-hover v-slot:default="{ hover }">
    <v-card :elevation="hover ? 2 : 0" height="240" outlined>
      <v-card :to="getAlbumUrl(album.url_name)" flat class="fill-height">
        <v-img
          :src="$imagehelpers.mediaWidth(album.image, 400)"
          height="150"
          class="grey lighten-2"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <v-card-title class="pa-3">
          <h2 class="subtitle-2 albums-header">
            {{ album.title | capitalize }}
          </h2>
        </v-card-title>
        <v-card-subtitle class="caption pl-3 pt-1">
          {{ album.unit_images }} {{ $t("Object") }}
          <privacy-icon :privacy="album.privacy"></privacy-icon>
        </v-card-subtitle>
      </v-card>
      <template v-if="is_admin">
        <button-menu
          color="secondary"
          fab
          x-small
          dark
          top
          right
          :items="admin_menu"
          absolute
          btn-icon="$vuetify.icons.fasPen"
          btn-class="mt-6 mr-n2 edit-btn"
        ></button-menu>
        <edit-dialog
          v-on:updated="onDescriptionUpdated"
          ref="editDialog"
        ></edit-dialog>
        <privacy-dialog ref="privacyDialog"></privacy-dialog>
        <v-overlay :value="is_updating" absolute>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </template>
    </v-card>
  </v-hover>
</template>


<i18n>
    {
    "en": {
      "Object": "object",
      "EditInformation": "Edit information",
      "EditPrivacy": "Edit privacy",
      "DeleteAlbum": "Delete album",
      "ManageImages": "Manage photos",
      "ConfirmDeleteAlbum": "Are you sur you want to delete this album? All photos in album will be deleted. This can't be undone.",
      "SuccessDelete": "Album deleted!"
    },
    "sv": {
      "Object": "objekt",
      "EditInformation": "Redigera information",
      "EditPrivacy": "Redigera sekretess",
      "DeleteAlbum": "Ta bort album",
      "ManageImages": "Hantera bilder",
      "ConfirmDeleteAlbum": "Är du säker på att du vill ta bort albumet? Samtliga bilder i albumet kommer att tas bort. Detta går inte att ångra.",
      "SuccessDelete": "Albumet togs bort!"
    }
    }
</i18n>



<script>
import { mapState, mapActions } from "vuex";
const EditDialog = () => import("../dialogs/AdminAlbumDialog.vue");
const PrivacyDialog = () => import("../dialogs/PrivacyDialog.vue");
import PrivacyIcon from "../../global/Privacy.vue";

export default {
  name: "AlbumItem",
  props: ["album"],
  computed: {
    ...mapState({
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
    }),
    admin_menu: function () {
      var data = [];

      if (this.album.can_edit) {
        data.push({
          name: this.$t("EditInformation"),
          icon: "$vuetify.icons.fileDocumentEditOutline",
          click: this.editDescription,
        });

        data.push({
          name: this.$t("EditPrivacy"),
          icon: "$vuetify.icons.earth",
          click: this.openPrivacyDialog,
        });
      }
      data.push({
        name: this.$t("ManageImages"),
        icon: "$vuetify.icons.imageOutline",
        to: this.getAlbumUrl(this.album.url_name + "/manage"),
      });

      if (this.album.can_delete)
        data.push({
          name: this.$t("DeleteAlbum"),
          icon: "$vuetify.icons.trashCanOutline",
          click: this.deleteAlbum,
        });

      return data;
    },
  },
  components: {
    "edit-dialog": EditDialog,
    "privacy-dialog": PrivacyDialog,
    "privacy-icon": PrivacyIcon,
  },
  data: () => ({
    is_updating: false,
  }),
  methods: {
    ...mapActions({
      deleteAlbumDb: "diary/albums/delete",
    }),
    getAlbumUrl(urlName) {
      return "/" + this.diary.name + "/album/" + urlName;
    },
    editDescription() {
      this.$refs.editDialog.editAlbum(this.album);
    },
    async deleteAlbum() {
      var self = this;

      const res = await self.$confirm(self.$t("ConfirmDeleteAlbum"));

      if (res) {
        self.is_updating = true;
        self
          .deleteAlbumDb(self.album.id)
          .then(function () {
            self.$successNoty(self.$t("SuccessDelete"));
            self.$emit("deleted", self.media.id);
          })
          .catch(function (error) {
            self.$ajaxErrorNoty(error);
            self.is_updating = false;
          });
      }
    },
    onDescriptionUpdated(data) {
      this.$emit("updated", data);
    },
    openPrivacyDialog() {
      this.$refs.privacyDialog.openAlbum(this.album);
    },
  },
};
</script>

<style scoped>
.edit-btn {
  opacity: 0.9;
}
</style>