<template>
  <v-skeleton-loader :loading="is_loading" type="list-item-avatar-three-line">
    <div>
      <template v-if="!error">
        <v-timeline align-top dense class="mt-4 ml-n5">
          <v-timeline-item right v-if="user">
            <template v-slot:icon>
              <user-avatar :user="user" :size="50"></user-avatar>
            </template>
            <v-card outlined>
              <v-form ref="create_form" v-model="create_form">
                <v-card-text class="pa-0">
                  <v-textarea
                    auto-grow
                    flat
                    solo
                    counter="700"
                    :rules="[(v) => !!v || '']"
                    required
                    :disabled="is_creating"
                    v-model="comment.text"
                    :label="$t('LeaveComment')"
                  ></v-textarea>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="createComment"
                    :loading="is_creating"
                    color="primary"
                    class="text-none"
                    >{{ $t("Button.Publish") }}</v-btn
                  >
                </v-card-actions>
                <error-box class="mt-2" :error="create_error"></error-box>
              </v-form>
            </v-card>
          </v-timeline-item>
          <comment-item
            :data-service="dataService"
            v-on:answerDeleted="onDeletedAnswer"
            v-on:deleted="onDeleted"
            v-on:answer="onAnswer"
            v-on:userBlocked="onUserBlocked"
            v-on:userUnblocked="onUserUnblocked"
            :comment="item"
            v-for="item in comments"
            :key="item.id"
          ></comment-item>
        </v-timeline>

        <v-col
          cols="12"
          v-if="!comments || comments.length < 1"
          class="text-center pt-10"
          >{{ $t("NoComments") }}</v-col
        >

        <v-col cols="12" class="text-center" v-if="unit_pages > 1">
          <v-pagination
            v-model="page"
            :length="unit_pages"
            :total-visible="7"
          ></v-pagination>
        </v-col>
      </template>
      <error-box :error="error" class="mt-4"></error-box>
    </div>
  </v-skeleton-loader>
</template>

<i18n>
    {
    "en": {
    "NoComments": "No comments written on this photo yet.",
    "LeaveComment": "Leave a comment...",
    "SuccessPublish": "Comment published!",
    "SuccessPublishAndModerate": "Your comment has been sent and is awaiting approval."
    },
    "sv": {
    "NoComments": "Ingen har skrivit något än. Starta diskussionen!",
    "LeaveComment": "Skriv en kommentar...",
    "SuccessPublish": "Kommentaren publicerades!",
    "SuccessPublishAndModerate": "Din kommentar har skickats och inväntar godkännande."
    }
    }
</i18n>

<script>
import { mapState } from "vuex";
import { mediacommentsService } from "../../../_services";
import CommentItem from "./CommentItem.vue";
import UserAvatar from "@/components/avatars/UserAvatar.vue";
export default {
  name: "diary_media_comments",
  props: ["media"],
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
    }),
  },
  components: {
    "comment-item": CommentItem,
    "user-avatar": UserAvatar
  },
  data: () => ({
    dataService: mediacommentsService,
    page: 1,
    unit_pages: 1,
    comments: [],
    error: null,
    is_loading: false,

    comment: {
      text: "",
    },
    create_form: false,
    is_creating: false,
    create_error: null,
  }),
  created: function () {
    this.setFromUrl();
    this.loadComments();
  },
  methods: {
    loadComments() {
      var self = this;
      self.error = null;
      self.is_loading = true;

      mediacommentsService
        .load(self.media.id, self.page)
        .then(function (response) {
          self.comments = self.formatComments(response.data.data);
          self.unit_pages = response.data.unit_pages;
          self.page = response.data.current_page;
          self.is_loading = false;

          if (self.page > self.unit_pages && self.unit_pages > 0) {
            self.$router.push({ query: { page: 1 } });
          }
        })
        .catch(function (error) {
          self.error = error;
          self.is_loading = false;
        });
    },
    setFromUrl() {
      this.page = 1;

      if (this.$route.query.page) this.page = parseInt(this.$route.query.page);
    },
    formatComments(data) {
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        obj.link =
          "/" +
          this.diary.name +
          "/album/media/" +
          this.media.id +
          "/comment/" +
          obj.id;
      }

      return data;
    },
    createComment() {
      var self = this;
      self.is_creating = false;
      self.create_error = null;

      if (self.$refs.create_form.validate()) {
        self.is_creating = true;

        mediacommentsService
          .create(self.media.id, self.comment)
          .then(function (response) {
            self.is_creating = false;
            self.comment.text = "";

            if (response.data.is_approved) {
              if (self.page == 1) {
                self.loadComments();
              } else {
                self.page = 1;
              }
              
              self.$successNoty(self.$t("SuccessPublish"));
            } else {
              self.$successNoty(self.$t("SuccessPublishAndModerate"));
            }
            self.$refs.create_form.resetValidation();
          })
          .catch(function (error) {
            self.create_error = error;
            self.is_creating = false;
          });
      }
    },
    onAnswer({ thread_id, comment }) {
      for (var i = 0; i < this.comments.length; i++) {
        var obj = this.comments[i];

        if (obj.id == thread_id) {
          if (obj.answers) {
            obj.answers.push(comment);
          } else {
            obj.answers = [];
            obj.answers.push(comment);
          }
        }
      }
    },
    onDeleted(id) {
      if (this.unit_pages > 1) {
        this.loadComments();
      } else {
        for (var i = 0; i < this.comments.length; i++) {
          var obj = this.comments[i];

          if (obj.id == id) {
            this.comments.splice(i, 1);
          }
        }
      }
    },
    onDeletedAnswer(id) {
      for (var i = 0; i < this.comments.length; i++) {
        var obj = this.comments[i];

        if (obj.answers) {
          for (var j = 0; j < obj.answers.length; j++) {
            var ans = obj.answers[j];

            if (ans.id == id) {
              obj.answers.splice(j, 1);
            }
          }
        }
      }
    },
    onUserBlocked(username) {
      this.changeUserBlockStatus(username, true);
    },
    onUserUnblocked(username) {
      this.changeUserBlockStatus(username, false);
    },
    changeUserBlockStatus(username, status) {
        this.comments.forEach(comment => {
          if(comment.user.username == username) {
            comment.user.is_blocked = status;
          }          
        });
    }
  },
  watch: {
    $route() {
      this.setFromUrl();
      this.loadComments();
    },
    page() {
      var self = this;

      if (parseInt(self.$route.query.page) == self.page) return;
      self.$router.push({ query: { page: self.page } });
    },
  },
};
</script>

<style>
</style>